<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="2"/>
    <!-- 身体 -->
    <div class="main">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <img src="../../assets/imgs/mini_program_banner.jpg" class="banner_img" alt />
        <!-- <div class="banner_box">
          <div class="banner_box_title">微信小程序定制开发,</div>
          <div class="banner_box_title">
            抢占
            <span>10+亿</span>微信流量红利
          </div>
          <div class="banner_box_txt">拥有丰富的小程序开发经验</div>
          <div class="banner_box_txt">根据业务需求,提供个性化定制的小程序方案</div>
          <div class="banner_box_txt">共享10+亿微信活跃用户流量</div>
          <div class="banner_box_border"></div>
        </div> -->
      </div>

      <!-- 主体 -->
      <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <!-- 方案 -->
        <div class="main_box_top">
          <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
            <div>小程序解决方案</div>
            <div class="title_box_text">各类小程序开发方案，满足企业需求，一站式打通线上线下</div>
          </div>
          <div class="main_box_top_btn wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_r_item" v-for="(item, index) in list" :key="index">
              <div class="main_box_top_r_img_box">
                <img :src="item.image" class="main_box_top_r_img" alt />
              </div>
              <div class="main_box_top_r_item_title">{{ item.title }}</div>
              <div class="main_box_top_r_item_txt" v-for="(itm, idx) in item.content" :key="idx">
                <span></span>
                {{ itm }}
              </div>
            </div>
          </div>
        </div>
        <!-- 定制 -->
        <div class="main_box_center wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
            <div>高端定制小程序商城</div>
            <div class="title_box_text">帮您实现触手可及的梦想</div>
          </div>
          <div class="main_box_center_box wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_center_item" v-for="(item, index) in list1" :key="index">
              <div class="main_box_center_item_img_box">
                <img :src="item.image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_border"></div>
              <div class="main_box_center_item_txt">{{ item.title }}</div>
            </div>
            <div class="main_box_center_r wow animate__slideInUp" data-wow-delay="0.4s" data-wow-iteration="1"
              :data-wow-offset="height_top">
              <div @click="more">了解更多，获取报价</div>
            </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
          <div>推荐案例</div>
          <div class="title_box_text">眼光高度决定品牌厚度！</div>
        </div>
        <div class="main_box_center_btn wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
          <div @click="toDetails(item)" v-for="(item, index) in list2" :key="index"
            class="main_box_center_btn_box wow animate__slideInUp" :data-wow-delay="index / 10 + 0.2 + 's'"
            data-wow-iteration="1" :data-wow-offset="height_top">
            <div class="main_box_center_btn_item">
              <div class="main_box_center_btn_item_img_box" ref="btnItem" :style="{ height: tempHeight + 'px' }">
                <img :src="item.list_image1" class="main_box_center_btn_item_img" alt />
              </div>
              <div class="main_box_center_btn_item_box">
                <div class="main_box_center_btn_item_box_title">{{ item.title }}</div>
                <div class="main_box_center_btn_item_box_txt">{{ item.summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articlellist15, articlellist16, projectsrecommends } from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      height_top: storage.height_top,
      tempHeight: "",
      timer: null,
      list: [],
      list1: [],
      list2: []
    };
  },
  methods: {
    // 更多
    more() {
      console.log(111);
      window.open("https://w102.ttkefu.com/k/linkurl/?t=3E4AAG8")
    },
    // 案例详情
    toDetails(e) {
      this.$router.push({
        name: "sucsessdetails",
        query: {
          id: e.id
        }
      });
    },
    // 设置高度
    height() {
      //进入页面元素默认宽度
      this.timer = setInterval(() => {
        if (!this.$refs.btnItem) {
          this.tempHeight = this.$refs.btnItem[0].offsetWidth;
          clearInterval(this.timer)
          this.timer = null
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 0);
      //根据屏幕缩放自动获取页面宽度
      window.onresize = () => {
        return (() => {
          //窗口缩放自动获取元素宽度
          this.tempHeight = this.$refs.btnItem[0].offsetWidth; //宽度
        })();
      };
    },
    // 获取数据
    async getAdlist() {
      // 获取解决方案
      let res = await articlellist15();
      this.list = res.data.list;
      // 获取定制商城
      let rec = await articlellist16();
      this.list1 = rec.data.list;
      // 案例推荐
      let ret = await projectsrecommends(3);
      this.list2 = ret.data.list;
      if (ret.data.list.length) {
        this.height();
      }
    },
    // 缩放比例
    resize() {
      // 系统整体缩放
      let cliWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      let cliHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let contW = cliWidth;
      let contH = cliHeight;
      let w = cliWidth / contW;
      let h = cliHeight / contH;
      let appDom = document.querySelector(".container");
      appDom.style.transform = "scale(" + w + "," + h + ")";
      appDom.style.transformOrigin = "top left";
      appDom.style.width = contW + "px";
      appDom.style.height = contH + "px";
    }
  },
  created() { },
  mounted() {
    this.getAdlist();
    this.$nextTick(() => {
      // this.resize();
      // window.onresize = function() {
      //   this.resize();
      // }.bind(this);
      let wow = new WOW({
        live: true
      });
      wow.init();
    });
  },
  watch: {},
  beforeDestroy() {
    console.log("销毁定时器");
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .banner {
    color: #fff;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .banner_img {
      // position: absolute;
      // height: 800px;
      width: 100%;
    }

    .banner_box {
      padding: 0 10%;
      position: relative;
      z-index: 1;

      .banner_box_title {
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 30px;

        span {
          color: #d42b2e;
        }
      }

      .banner_box_title:nth-child(1) {
        margin: 0;
      }

      .banner_box_txt {
        font-size: 28px;
        line-height: 50px;
      }

      .banner_box_border {
        width: 45px;
        height: 2px;
        background: #ffffff;
        margin: 30px 0;
      }
    }
  }

  // 身体
  .main_box {
    background-color: #fff;

    // 方案
    .main_box_top {
      padding: 0 10%;
      box-sizing: border-box;

      .title_box {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 28px;
        background-color: #fff;

        .title_box_text {
          margin-top: 21px;
          font-weight: normal;
          color: #666666;
          font-size: 18px;
        }
      }

      .main_box_top_l:hover {
        border-radius: 50%;
        transition: all 0.5s;
        box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      }

      .main_box_top_btn {
        display: flex;
        flex-wrap: wrap;

        .main_box_top_r_item {
          width: calc(96% / 3);
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          color: #999;
          font-size: 16px;
          transition: all 0.5s;
          box-shadow: 0px 2px 13px 1px rgba(4, 0, 0, 0.07);
          margin-bottom: 2%;
          background: url("../../assets/imgs/mini_program_23_bg.jpg") no-repeat;
          background-size: 100% 100%;

          .main_box_top_r_img_box {
            width: 54px;
            height: 56px;
            margin: 15px 0;

            .main_box_top_r_img {
              width: 100%;
              height: 56px;
            }
          }

          .main_box_top_r_item_title {
            font-size: 26px;
            color: #333333;
            margin-bottom: 30px;
          }

          .main_box_top_r_item_txt {
            font-size: 16px;
            color: #999999;
            line-height: 34px;

            span {
              display: inline-block;
              width: 9px;
              height: 9px;
              background: #ffffff;
              border: 2px solid #d42b2e;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }

        .main_box_top_r_item:nth-child(1) {
          background: url("../../assets/imgs/mini_program_23_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(2) {
          background: url("../../assets/imgs/mini_program_24_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(3) {
          background: url("../../assets/imgs/mini_program_25_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(4) {
          background: url("../../assets/imgs/mini_program_26_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(5) {
          background: url("../../assets/imgs/mini_program_27_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(6) {
          background: url("../../assets/imgs/mini_program_28_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(2),
        .main_box_top_r_item:nth-child(5) {
          margin: 0 2%;
          margin-bottom: 2%;
        }

        .main_box_top_r_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }
      }
    }

    // 定制
    .main_box_center {
      padding: 0 10%;

      .title_box {
        padding: 1% 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 28px;
        background-color: #fff;

        .title_box_text {
          margin-top: 21px;
          font-weight: normal;
          color: #666666;
          font-size: 18px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .main_box_center_item {
          width: calc(100% / 8);
          margin-bottom: 3%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 112px;
            height: 112px;
            background: #f5f5f5;
            border-radius: 50%;
            transition: all 0.5s;

            .main_box_center_item_img {
              width: 36px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_border {
            width: 24px;
            height: 2px;
            background: #d42b2e;
            margin: 10px 0 20px 0;
            transition: all 0.5s;
          }

          .main_box_center_item_txt {
            color: #333333;
            font-size: 18px;
            transition: all 0.5s;
          }
        }

        .main_box_center_item:hover .main_box_center_item_img_box {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_item:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }

        .main_box_center_item:hover .main_box_center_item_txt {
          font-weight: bold;
          transition: all 0.5s;
        }

        .main_box_center_item:hover .main_box_center_item_border {
          width: 40px;
          color: red;
          transition: all 0.5s;
        }

        .main_box_center_r {
          width: calc(100% / 8 * 2);
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            width: 274px;
            font-size: 22px;
            color: #ffffff;
            height: 90px;
            line-height: 90px;
            background: #d42b2e;
            border-radius: 10px;
            cursor: pointer;
            text-align: center;
            transition: all 0.5s;
          }

          div:hover {
            background-color: red;
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          }
        }
      }
    }

    // 推荐
    .title_box {
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 28px;
      background-color: #f5f5f5;

      .title_box_text {
        margin-top: 21px;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
      }
    }

    .main_box_center_btn {
      padding: 0 10%;
      padding-bottom: 66px;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      align-items: center;

      .main_box_center_btn_box {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;

        .main_box_center_btn_item {
          // width: 407px;
          width: 100%;
          // height: 514px;
          background: #ffffff;
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.5s;

          .main_box_center_btn_item_img_box {
            width: 100%;
            // height: 378px;
            overflow: hidden;

            .main_box_center_btn_item_img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
          }

          .main_box_center_btn_item_box {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8% 0;

            .main_box_center_btn_item_box_title {
              color: #333333;
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 5%;
            }

            .main_box_center_btn_item_box_txt {
              color: #999999;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .main_box_center_btn_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_btn_item:hover .main_box_center_btn_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }

      .main_box_center_btn_box:nth-child(2) {
        margin: 0 5%;
      }
    }
  }
}

@media screen and (max-width : 1399px) {
  .banner {
    color: #fff;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1300px;

    .banner_img {
      // position: absolute;
      // height: 800px;
      width: 100%;
    }

    .banner_box {
      padding: 0 20px;
      position: relative;
      z-index: 1;

      .banner_box_title {
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 30px;

        span {
          color: #d42b2e;
        }
      }

      .banner_box_title:nth-child(1) {
        margin: 0;
      }

      .banner_box_txt {
        font-size: 28px;
        line-height: 50px;
      }

      .banner_box_border {
        width: 45px;
        height: 2px;
        background: #ffffff;
        margin: 30px 0;
      }
    }
  }

  // 身体
  .main_box {
    background-color: #fff;
    min-width: 1300px;
    // 方案
    .main_box_top {
      padding: 0 20px;
      box-sizing: border-box;

      .title_box {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 28px;
        background-color: #fff;

        .title_box_text {
          margin-top: 21px;
          font-weight: normal;
          color: #666666;
          font-size: 18px;
        }
      }

      .main_box_top_l:hover {
        border-radius: 50%;
        transition: all 0.5s;
        box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      }

      .main_box_top_btn {
        display: flex;
        flex-wrap: wrap;

        .main_box_top_r_item {
          width: calc(96% / 3);
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          color: #999;
          font-size: 16px;
          transition: all 0.5s;
          box-shadow: 0px 2px 13px 1px rgba(4, 0, 0, 0.07);
          margin-bottom: 2%;
          background: url("../../assets/imgs/mini_program_23_bg.jpg") no-repeat;
          background-size: 100% 100%;

          .main_box_top_r_img_box {
            width: 54px;
            height: 56px;
            margin: 15px 0;

            .main_box_top_r_img {
              width: 100%;
              height: 56px;
            }
          }

          .main_box_top_r_item_title {
            font-size: 26px;
            color: #333333;
            margin-bottom: 30px;
          }

          .main_box_top_r_item_txt {
            font-size: 16px;
            color: #999999;
            line-height: 34px;

            span {
              display: inline-block;
              width: 9px;
              height: 9px;
              background: #ffffff;
              border: 2px solid #d42b2e;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }

        .main_box_top_r_item:nth-child(1) {
          background: url("../../assets/imgs/mini_program_23_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(2) {
          background: url("../../assets/imgs/mini_program_24_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(3) {
          background: url("../../assets/imgs/mini_program_25_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(4) {
          background: url("../../assets/imgs/mini_program_26_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(5) {
          background: url("../../assets/imgs/mini_program_27_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(6) {
          background: url("../../assets/imgs/mini_program_28_bg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        .main_box_top_r_item:nth-child(2),
        .main_box_top_r_item:nth-child(5) {
          margin: 0 2%;
          margin-bottom: 2%;
        }

        .main_box_top_r_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }
      }
    }

    // 定制
    .main_box_center {
      padding: 0 20px;

      .title_box {
        padding: 1% 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 28px;
        background-color: #fff;

        .title_box_text {
          margin-top: 21px;
          font-weight: normal;
          color: #666666;
          font-size: 18px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .main_box_center_item {
          width: calc(100% / 8);
          margin-bottom: 3%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 112px;
            height: 112px;
            background: #f5f5f5;
            border-radius: 50%;
            transition: all 0.5s;

            .main_box_center_item_img {
              width: 36px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_border {
            width: 24px;
            height: 2px;
            background: #d42b2e;
            margin: 10px 0 20px 0;
            transition: all 0.5s;
          }

          .main_box_center_item_txt {
            color: #333333;
            font-size: 18px;
            transition: all 0.5s;
          }
        }

        .main_box_center_item:hover .main_box_center_item_img_box {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_item:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }

        .main_box_center_item:hover .main_box_center_item_txt {
          font-weight: bold;
          transition: all 0.5s;
        }

        .main_box_center_item:hover .main_box_center_item_border {
          width: 40px;
          color: red;
          transition: all 0.5s;
        }

        .main_box_center_r {
          width: calc(100% / 8 * 2);
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            width: 274px;
            font-size: 22px;
            color: #ffffff;
            height: 90px;
            line-height: 90px;
            background: #d42b2e;
            border-radius: 10px;
            cursor: pointer;
            text-align: center;
            transition: all 0.5s;
          }

          div:hover {
            background-color: red;
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          }
        }
      }
    }

    // 推荐
    .title_box {
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 28px;
      background-color: #f5f5f5;

      .title_box_text {
        margin-top: 21px;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
      }
    }

    .main_box_center_btn {
      padding: 0 20px;
      padding-bottom: 66px;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      align-items: center;

      .main_box_center_btn_box {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;

        .main_box_center_btn_item {
          // width: 407px;
          width: 100%;
          // height: 514px;
          background: #ffffff;
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.5s;

          .main_box_center_btn_item_img_box {
            width: 100%;
            // height: 378px;
            overflow: hidden;

            .main_box_center_btn_item_img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
          }

          .main_box_center_btn_item_box {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8% 0;

            .main_box_center_btn_item_box_title {
              color: #333333;
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 5%;
            }

            .main_box_center_btn_item_box_txt {
              color: #999999;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .main_box_center_btn_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_btn_item:hover .main_box_center_btn_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }

      .main_box_center_btn_box:nth-child(2) {
        margin: 0 1%;
      }
    }
  }
}
</style>
